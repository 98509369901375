import { logError } from 'API/ApiHelpers';
import { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    let errorMessage = 'FXJ FJ_JOBS Client error: ' + error.message;
    let errorDescription = 'error.stack: ' + error.stack;
    errorDescription +=
      '\n- errorInfo.componentStack: ' + errorInfo.componentStack;
    logError(errorMessage, errorDescription, false);
  }

  public render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
